import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";
import MaterialTable ,{ MTableToolbar }from 'material-table';
import PropTypes from 'prop-types';
import { makeStyles ,withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import { ExportToCsv } from "export-to-csv";

import { useSelector, useDispatch } from "react-redux";
import { setMemberMonthly ,setMemberWeekly} from '../../../../redux/actions/productActions';

const TabPanel = (props) =>{
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    textTransform: 'none',
    // color: '#fff',
  },
  indicator: {
    backgroundColor: '#533278',
  },
  textColor:{
      color:'#533278'
    
  }
 
}));

const StyledRadio = withStyles({
  root: {
    color: "#533278",
    '&$checked': {
      color: '#533278',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const MemberAggregateReport = () => {
  const [loadWeek, setLoadWeek] = useState(false);
  const [loadMonth, setLoadMonth] = useState(false);

  const [weekList, setWeekList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [value, setValue] =useState(0);
  const [selectedValue, setSelectedValue] = React.useState('month');
  const classes = useStyles();

    let username = localStorage.getItem(
      "CognitoIdentityServiceProvider.3dqtf4c0g43c047gkrsadgutp6.LastAuthUser"
    );
    let id_token = localStorage.getItem(
      "CognitoIdentityServiceProvider.3dqtf4c0g43c047gkrsadgutp6." +
        username +
        ".idToken"
    );
  const dataList = useSelector((state) => state);
  const dispatch = useDispatch();
  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: "MemberAggregateReport"
  };
  const csvExporter = new ExportToCsv(options);
  const handleChange = (event, newValue) => {
    console.log(event.target.innerText)
    if(event.target.innerText=="BY WEEK"){
      setSelectedValue('week')
    }else{
      setSelectedValue('month')
    }
    setValue(newValue);
     
  };
  async function fetchMonthList() {
    setLoadMonth(true);
    const request = await axios.get(
      config.regulatorMemberMonthlyReport,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
    dispatch(setMemberMonthly(request.data));
    setMonthList(request.data);
    setLoadMonth(false);
  }
  async function fetchWeekList() {
    setLoadWeek(true)
    const request = await axios.get(
      config.regulatorMemberWeeklyReport,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
    dispatch(setMemberWeekly(request.data));
    setWeekList(request.data);
    setLoadWeek(false);
  }
  async function downloadWeekList() {
  
    const request = await axios.get(
      config.regulatorMemberWeeklyReport,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
  
    csvExporter.generateCsv(request.data);
  }
  async function downloadMonthList() {
  
    const request = await axios.get(
      config.regulatorMemberMonthlyReport,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
  
    csvExporter.generateCsv(request.data);
  }
  useEffect(() => {
    setMonthList(dataList.channels.memberMonthly);
    setWeekList(dataList.channels.memberWeekly);

    if (dataList.channels.memberMonthly.length === 0) {
      setLoadMonth(true);

      fetchMonthList();
    }
    console.log(dataList.channels)

    if (dataList.channels.memberWeekly.length === 0) {
      setLoadWeek(true);
 
      fetchWeekList();
    }

  }, [weekList, monthList]);
  
  const week_columns = [
    {
      field: "week",
      title: "Week"
    },

    {
      field: "month",
      title: "Month"
    },
    {
      field: "year",
      title: "Year"
    },
    {
      field: "net_smartshare",
      title: "Net Smartshare"
    },
    {
      field: "cumulative_smartshare",
      title: "Cumulative Smartshare"
    },
    {
      field: "net_healthshare",
      title: "Net Healthshare	"
    },
    {
      field: "cumulative_healthshare",
      title: "Cumulative Healthshare	"
    },
    {
      field: "cumulative_active",
      title: "Cumulative Active"
    }
  ];
  const columns = [
  
    {
      field: "month",
      title: "Month"
    },
    {
      field: "year",
      title: "Year"
    },
    {
      field: "net_smartshare",
      title: "Net Smartshare"
    },
    {
      field: "cumulative_smartshare",
      title: "Cumulative Smartshare"
    },
    {
      field: "net_healthshare",
      title: "Net Healthshare	"
    },
    {
      field: "cumulative_healthshare",
      title: "Cumulative Healthshare	"
    },
    {
      field: "cumulative_active",
      title: "Cumulative Active"
    }
  ];
  return (
    <ContentWrapper>
    <span className="pageheading">Member Aggregate Report </span>

    <div className="tableBody clearfix">
    <AppBar position="static">
      <Tabs value={value} onChange={handleChange} 
      classes={{
        indicator: classes.indicator
      }}
      aria-label="simple tabs example">
        <Tab label={<span style={{ color: '#533278' }}>
           <StyledRadio
              checked={selectedValue === 'month'}
              disabled
              value="month"
              name="radio-button"
              disableRipple
              inputProps={{ 'aria-label': 'MONTH' }}
             />
           
           BY MONTH</span>} />
        <Tab label={<span style={{ color: '#533278' }}> 
        <StyledRadio
              disableRipple
              disabled
              checked={selectedValue === 'week'}
              value="week"
              name="radio-button"
              inputProps={{ 'aria-label': 'WEEK' }}
             />
        BY WEEK</span>} />
      </Tabs>
    </AppBar>
    <TabPanel value={value} index={0}>
    
      {loadMonth === true && <div className="loader" />}
      {loadMonth === true && (
        <div style={{ textAlign: "center" }}>Loading Monthly List</div>
      )}

      {loadMonth === false && (
        <div className="tableInnerDiv">
          <MaterialTable
        // other props
        components={{
          
          Toolbar: props => (
              <div style={{ backgroundColor: '#f6f6e6' }}>
                  <MTableToolbar {...props} />


              </div>
          )
      }}

        columns={columns}
        data={monthList}
        options={{pageSize:10,searchFieldAlignment:"left",searchFieldStyle:{
          backgroundColor:"white",
          
        }}}
        title={false}
        actions={[
          {
            icon: 'save_alt',
            tooltip: 'Export Data',
            isFreeAction: true,
            iconProps:{style:{backgroundColor:"#eb5757",width:"35px",height:"35px",borderRadius:"20px",paddingTop:"6px",color:"white"}},
            onClick: () => downloadMonthList(),
          },
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            isFreeAction: true,
            iconProps:{style:{backgroundColor:"#41b5c2",width:"35px",height:"35px",borderRadius:"20px",paddingTop:"6px",color:"white"}},
            onClick: () => fetchMonthList(),
          }
        ]}
      
        />
         
        </div>
      )}
   
    </TabPanel>
    <TabPanel value={value} index={1}>
    
      {loadWeek === true && <div className="loader" />}
      {loadWeek === true && (
        <div style={{ textAlign: "center" }}>Loading Weekly List</div>
      )}

      {loadWeek === false && (
        <div className="tableInnerDiv" >
          <MaterialTable
        // other props
        components={{
          
          Toolbar: props => (
              <div style={{ backgroundColor: '#f6f6e6' }}>
                  <MTableToolbar {...props} />


              </div>
          )
      }}

        columns={week_columns}
        data={weekList}
        options={{pageSize:10,searchFieldAlignment:"left",searchFieldStyle:{
          backgroundColor:"white",
          
        }}}
        title={false}
        actions={[
          {
            icon: 'save_alt',
            tooltip: 'Export Data',
            isFreeAction: true,
            iconProps:{style:{backgroundColor:"#eb5757",width:"35px",height:"35px",borderRadius:"20px",paddingTop:"6px",color:"white"}},
            onClick: () => downloadWeekList(),
          },
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            isFreeAction: true,
            iconProps:{style:{backgroundColor:"#41b5c2",width:"35px",height:"35px",borderRadius:"20px",paddingTop:"6px",color:"white"}},
            onClick: () => fetchWeekList(),
          }
        ]}
      
        />
        </div>
      )}
  
    </TabPanel>
    </div>
  </ContentWrapper>
  );
};

export default MemberAggregateReport;

