import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";
import { ExportToCsv } from "export-to-csv";
import { useSelector, useDispatch } from "react-redux";
import { setAggregateReportByChannel } from '../../../../redux/actions/productActions';
import MaterialTable ,{ MTableToolbar }from 'material-table';


const AggregateReportByChannel = () => {
const [load, setLoad] = useState(false);
const [agentList, setAgentList] = useState([]);
const [agentListCall, setAgentListCall] = useState(true);

  let username = localStorage.getItem(
    "CognitoIdentityServiceProvider.3dqtf4c0g43c047gkrsadgutp6.LastAuthUser"
  );
  let id_token = localStorage.getItem(
    "CognitoIdentityServiceProvider.3dqtf4c0g43c047gkrsadgutp6." +
      username +
      ".idToken"
  );
const dataList = useSelector((state) => state);
const dispatch = useDispatch();
const options = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  filename: "Monthly Needs Aggregate"
};
const csvExporter = new ExportToCsv(options);
async function fetchList() {
  setLoad(true);
  const request = await axios.get(
      config.regulatorPayableAggregateReportByChannel,
      {
          headers: {
              "Content-Type": "application/json",
              "x-api-key": apikey.xapikey,
              token: id_token
          }
      }
  );
  dispatch(setAggregateReportByChannel(request.data));
  setAgentList(request.data);
  setLoad(false);
}
async function downloadList() {
  
  const request = await axios.get(
    config.regulatorPayableAggregateReportByChannel,
    {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apikey.xapikey,
        token: id_token
      }
    }
  );

  csvExporter.generateCsv(request.data);
}
  useEffect(() => {

    setAgentList(dataList.channels.aggregateReportByChannel);

    if (dataList.channels.aggregateReportByChannel.length === 0) {
          setLoad(true);
          fetchList();
      }
  }, []);


    const columns = [
      {
        field: "affiliation_id",
        title: "Affiliation Id",
        headerStyle: { minWidth: "200px" }
      },
      {
        field: "caryn_management",
        title: "Caryn Management	",
        render: cell => {
          return (
            <>
              {cell.caryn_management.toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
              })}
            </>
          );
        }
      },
      {
        field: "hsblox",
        title: "Hsblox",
        render: cell => {
          return (
            <>
              {cell.hsblox.toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
              })}
            </>
          );
        }
      },
      {
        field: "merchant_processing",
        title: "Merchant Processing",
        render: cell => {
          return (
            <>
              {cell.merchant_processing.toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
              })}
            </>
          );
        }
      },
      {
        field: "misc",
        title: "Misc",
        render: cell => {
          return (
            <>
              {cell.misc.toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
              })}
            </>
          );
        }
      },
      {
        field: "needs_mitigation",
        title: "Needs Mitigation",
        render: cell => {
          return (
            <>
              {cell.needs_mitigation.toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
              })}
            </>
          );
        }
      },
      {
        field: "onetime_fulfillment",
        title: "Onetime Fulfillment",
        render: cell => {
          return (
            <>
              {cell.onetime_fulfillment.toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
              })}
            </>
          );
        }
      },
      {
        field: "provider_network",
        title: "Provider Network",
        render: cell => {
          return (
            <>
              {cell.provider_network.toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
              })}
            </>
          );
        }
      },
      {
        field: "telemed",
        title: "Telemed",
        render: cell => {
          return (
            <>
              {cell.telemed.toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
              })}
            </>
          );
        }
      },
      {
        field: "third_party_referral_fee",
        title: "Third Party Referral Fee",
        render: cell => {
          return (
            <>
              {cell.third_party_referral_fee.toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
              })}
            </>
          );
        }
      },
      {
        field: "third_party_sales_channel",
        title: "Third Party Sales Channel",
        render: cell => {
          return (
            <>
              {cell.third_party_sales_channel.toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
              })}
            </>
          );
        }
      },
      {
        field: "tpa",
        title: "TPA",
        render: cell => {
          return (
            <>
              {cell.tpa.toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
              })}
            </>
          );
        }
      },
      {
        field: "handling_fees",
        title: "Handling Fees",
        render: cell => {
          return (
            <>
              {cell.handling_fees.toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
              })}
            </>
          );
        }
      },
      {
        field: "uhf_monthly_dues",
        title: "Monthly Dues",
        render: cell => {
          return (
            <>
              {cell.uhf_monthly_dues.toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
              })}
            </>
          );
        }
      },
      // {
      //   field: "a",
      //   title: "Sharewallet",
      //   formatter: GetSharewallet
      // },
      // {
      //   field: "b",
      //   title: "Ops Admin",
      //   formatter: GetOperations
      // },
      // {
      //   field: "c",
      //   title: "Households",
      //   formatter: GetHousehold
      // }
    ];



    return (
      <ContentWrapper>
        <span className="pageheading">Aggregate Report By Channel </span>

        <div className="tableBody clearfix">
          {load === true && <div className="loader" />}
          {load === true && (
            <div style={{ textAlign: "center" }}>Loading List</div>
          )}

          {load === false && agentList.length === 0 && <div>NO data found</div>}
          {load === false && agentList.length > 0 && (
                   
                   <MaterialTable
                   // other props
                   components={{
                     
                     Toolbar: props => (
                         <div style={{ backgroundColor: '#f6f6e6' }}>
                             <MTableToolbar {...props} />
                      </div>
                     )
                 }}
       
                 columns={columns}
                 data={agentList}
                 detailPanel={rowData => {
                  return (
                    <div className="detailPanel">
                    <table className="detailPanelTable">

                      <tr className="detailPaneltr">
                        <th className="detailPanelth" style={{paddingLeft:"10px"}} scope="col">Sharewallet</th>
                        <th className="detailPanelth" style={{paddingLeft:"10px"}} scope="col">Handling Fees</th>
                        <th className="detailPanelth" style={{paddingLeft:"10px"}} scope="col">Households</th>  
                      </tr>
                      <tr>
                      <td className="detailPaneltd" >
                      <tr className="detailPaneltr">
                        <th className="detailPanelth" style={{paddingLeft:"10px"}} scope="col">Smartshare</th>
                        <th className="detailPanelth" style={{paddingLeft:"10px"}} scope="col">Healthshare</th>
                        <th className="detailPanelth" style={{paddingLeft:"10px"}} scope="col">Total</th>  
                      </tr>
                      <tr className="detailPaneltr">
                        <td className="detailPanelth" style={{paddingLeft:"10px"}} scope="col">{rowData.sharewallet[0].smartshare.toLocaleString("en-US", {style: "currency",currency: "USD"})}</td>
                        <td className="detailPanelth" style={{paddingLeft:"10px"}} scope="col">{rowData.sharewallet[0].non_smartshare.toLocaleString("en-US", {style: "currency",currency: "USD"})}</td>
                        <td className="detailPanelth" style={{paddingLeft:"10px"}} scope="col">{rowData.sharewallet[0].total.toLocaleString("en-US", {style: "currency",currency: "USD"})}</td>
                      </tr>
                      </td>
                      <td className="detailPaneltd" >
                      <tr className="detailPaneltr">
                        <th className="detailPanelth" style={{paddingLeft:"10px"}} scope="col">Smartshare</th>
                        <th className="detailPanelth" style={{paddingLeft:"10px"}} scope="col">Healthshare</th>
                        <th className="detailPanelth" style={{paddingLeft:"10px"}} scope="col">Total</th>  
                      </tr>
                      <tr className="detailPaneltr">
                        <td className="detailPanelth" style={{paddingLeft:"10px"}} scope="col">{rowData.uhf_operations_reserve[0].smartshare.toLocaleString("en-US", {style: "currency",currency: "USD"})}</td>
                        <td className="detailPanelth" style={{paddingLeft:"10px"}} scope="col">{rowData.uhf_operations_reserve[0].non_smartshare.toLocaleString("en-US", {style: "currency",currency: "USD"})}</td>
                        <td className="detailPanelth" style={{paddingLeft:"10px"}} scope="col">{rowData.uhf_operations_reserve[0].total.toLocaleString("en-US", {style: "currency",currency: "USD"})}</td>
                      </tr>
                      </td>
                      <td className="detailPaneltd" >
                      <tr className="detailPaneltr">
                        <th className="detailPanelth" style={{paddingLeft:"10px"}} scope="col">Smartshare</th>
                        <th className="detailPanelth" style={{paddingLeft:"10px"}} scope="col">Healthshare</th>
                        <th className="detailPanelth" style={{paddingLeft:"10px"}} scope="col">Total</th>  
                      </tr>
                      <tr className="detailPaneltr">
                        <td className="detailPanelth" style={{paddingLeft:"10px"}} scope="col">{rowData.household_size[0].smartshare}</td>
                        <td className="detailPanelth" style={{paddingLeft:"10px"}} scope="col">{rowData.household_size[0].non_smartshare}</td>
                        <td className="detailPanelth" style={{paddingLeft:"10px"}} scope="col">{rowData.household_size[0].total}</td>
                      </tr>
                      </td>
                      </tr>
                    
                  </table>
                  </div>
                  )
                }}
                 options={{pageSize:10,searchFieldAlignment:"left",searchFieldStyle:{
                   backgroundColor:"white",
                   
                 }}}
                 title={false}
                 actions={[
                   {
                     icon: 'save_alt',
                     tooltip: 'Export Data',
                     isFreeAction: true,
                     iconProps:{style:{backgroundColor:"#eb5757",width:"35px",height:"35px",borderRadius:"20px",paddingTop:"6px",color:"white"}},
                     onClick: () => downloadList(),
                   },
                   {
                     icon: 'refresh',
                     tooltip: 'Refresh Data',
                     isFreeAction: true,
                     iconProps:{style:{backgroundColor:"#41b5c2",width:"35px",height:"35px",borderRadius:"20px",paddingTop:"6px",color:"white"}},
                     onClick: () => fetchList(),
                   }
                 ]}
               
                 />
          )}
        </div>
      </ContentWrapper>
    );
};

export default AggregateReportByChannel;

