import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import {Auth} from "aws-amplify";

const Header = () => {
  const [isMyAccountOpen, setIsMyAccountOpen] = useState(false);
  const history = useHistory();


  const signOut = (e) => {
      e.preventDefault();
      Auth.signOut();

      localStorage.removeItem("userRole");
      localStorage.removeItem("PII");

      history.push({
        pathname: "/"
      });
    }






   const toggleMyAccount = () => {
     setIsMyAccountOpen(!isMyAccountOpen);
   };

   const settings = () => {
     history.push({
       pathname: "/Settings"
     });
   }

  return (
    <>
      <div
        style={{ marginLeft: "80%" }}
      >
      </div>
      <NotificationsIcon
        className="toggleSideIcon"
        fontSize="medium"
      />
     
      <div className={`${isMyAccountOpen ? "show" : ""}`}>
        <span
          className="header-icon d-flex align-items-center justify-content-center ml-2"
          data-toggle="dropdown"
          title="My Account"
          aria-expanded="false"
        >
          <AccountCircleIcon
            className="toggleSideIcon"
            fontSize="medium"
            onClick={toggleMyAccount}
          />
        </span>
        <div
          className={`dropdown-menu dropdown-menu-animated dropdown-lg`}
          style={{
            left: "-130px !important",
            top: "18px !important",
            cursor: "pointer"
          }}
        >
          <div className="dropdown-header bg-trans-gradient d-flex flex-row rounded-top">
            Profile
          </div>
          <div className="dropdown-divider m-0"></div>
          <div className="dropdown-header bg-trans-gradient d-flex flex-row rounded-top">
            My Account
          </div>
          <div className="dropdown-divider m-0"></div>
          <div
            className="dropdown-header bg-trans-gradient d-flex flex-row rounded-top"
            onClick={settings}
          >
            Settings
          </div>
          <div className="dropdown-divider m-0"></div>
          <div
            className="dropdown-header bg-trans-gradient d-flex flex-row rounded-top"
            onClick={signOut}
          >
            Sign out
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
