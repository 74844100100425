import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";
import MaterialTable ,{ MTableToolbar }from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import { setAgencyCommission } from '../../../../redux/actions/productActions';
import { ExportToCsv } from "export-to-csv";



const AgencyCommission = () => {

  const [load, setLoad] = useState(false);
  const [agentList, setAgentList] = useState([]);
  const [agentListCall, setAgentListCall] = useState(true);

    let username = localStorage.getItem(
      "CognitoIdentityServiceProvider.3dqtf4c0g43c047gkrsadgutp6.LastAuthUser"
    );
    let id_token = localStorage.getItem(
      "CognitoIdentityServiceProvider.3dqtf4c0g43c047gkrsadgutp6." +
        username +
        ".idToken"
    );
  const dataList = useSelector((state) => state);
  const dispatch = useDispatch();
  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: "Agency Commission"
  };
  const csvExporter = new ExportToCsv(options);
  async function fetchList() {
    setLoad(true);
    const request = await axios.get(
      
      config.regulatorAgencyCommission,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
    dispatch(setAgencyCommission(request.data));
    setAgentList(request.data);
    setLoad(false);
  }
  async function downloadList() {
    const request = await axios.get(
      
      config.regulatorAgencyCommission,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
  
    csvExporter.generateCsv(request.data);
  }
  
  useEffect(() => {
    if (agentListCall === true) {
      if (dataList.channels.agencyCommission.length > 0) {
        setAgentList(dataList.channels.agencyCommission);
      } else { 
        setLoad(true);
        setAgentListCall(false);
       
        fetchList();
      }
      
    }
  }, [
      agentListCall,
      agentList
    ]);

  const GetTotalCommission = (cell) => {
    var totalAmount = cell.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })
    return (
      <div>
        {totalAmount}
      </div>
    );
  };


  const columns = [
    {
      field: "channel_id",
      title: "Channel ID"
    },
    {
      field: "channel_name",
      title: "Channel Name"
    },
    {
      field: "total_commission",
      title: "Channel Commission",
      formatter: GetTotalCommission
    },
    // {
    //   field: "channel_category",
    //   title: "Channel Catogory"
    // }
  ];



  return (
    <ContentWrapper>
      <span className="pageheading">Agency Commission</span>

      <div className="tableBody clearfix">
        {load === true && <div className="loader" />}
        {load === true && (
          <div style={{ textAlign: "center" }}>Loading List</div>
        )}

        {load === false && (
           <MaterialTable
           // other props
           components={{
             
             Toolbar: props => (
                 <div style={{ backgroundColor: '#f6f6e6' }}>
                     <MTableToolbar {...props} />
 
 
                 </div>
             )
         }}
 
           columns={columns}
           data={agentList}
           options={{pageSize:10,searchFieldAlignment:"left",searchFieldStyle:{
             backgroundColor:"white",
             
           }}}
           title={false}
           actions={[
             {
               icon: 'save_alt',
               tooltip: 'Export Data',
               isFreeAction: true,
               iconProps:{style:{backgroundColor:"#eb5757",width:"35px",height:"35px",borderRadius:"20px",paddingTop:"6px",color:"white"}},
               onClick: () => downloadList()
             },
             {
               icon: 'refresh',
               tooltip: 'Refresh Data',
               isFreeAction: true,
               iconProps:{style:{backgroundColor:"#41b5c2",width:"35px",height:"35px",borderRadius:"20px",paddingTop:"6px",color:"white"}},
               onClick: () => fetchList()
             }
           ]}
         
           />
        )}
      </div>
    </ContentWrapper>
  );
};

export default AgencyCommission;

