import React, { useState, useEffect } from "react";
import ContentWrapper from "../../Common/ContentWrapper";
import axios from "../../../configuration/axios";
import apikey from "../../../configuration/apikey";
import config from "../../../configuration/config";


import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "bootstrap/dist/css/bootstrap.css";
const AdminRules = () => {

  const [load, setLoad] = useState(true);
  const [agentList, setAgentList] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const [currentDate, setCurrentDate] = useState("");
  const [saveValue, setSaveValue] = useState(0);
  const [agentListCall, setAgentListCall] = useState(true);

  const [isEdit, setIsEdit] = useState(0);
  const [save, setSave] = useState(false);
  const [update, setUpdate] = useState(false);
  const [deleteClick, setDeleteClick] = useState(false);
  const [saveData, setSaveData] = useState("");
  const [updateData, setUpdateData] = useState("");
  const [deleteExistingRule, setDeleteExistingRule] = useState("");

  const [ruleId, setRuleId] = useState("");
  const [commissionDate, setCommissionDate] = useState("");
  const [channelId, setChannelId] = useState("");
  const [channelName, setChannelName] = useState("");
  const [channelCategory, setChannelCategory] = useState("");
  const [referralChannel, setReferralChannel] = useState("");
  const [applicationFee, setApplicationFee] = useState("");
  const [monthlyShare, setMonthlyShare] = useState("");
  const [montyhlyDue, setMontyhlyDue] = useState("");


  let id_token = localStorage.getItem(config.id_tokenAdmin);

      async function fetchList() {
        const request = await axios.get(config.agentRule, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": apikey.xapikey,
            token: id_token
          }
        });
        setAgentList(request.data);
        setLoad(false);
      }

      async function fetchChannelList() {
        const request = await axios.get(config.channelList, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": apikey.xapikey,
            token: id_token
          }
        });
        setChannelList(request.data);
      }
  useEffect(() => {
    const now = new Date();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now
      .getDate()
      .toString()
      .padStart(2, "0");
    setCurrentDate(`${now.getFullYear()}-${month}-${day}`);

    if (agentListCall === true) {
      setAgentListCall(false);

      fetchList();
    }

    if (channelList.length === 0) {

      fetchChannelList();
    }

    if (save === true) {
      setSave(false);

      fetch(config.baseURL + config.agentRule,
        {
          method: "POST",
          body: JSON.stringify(saveData),
          headers: {
            "Content-Type":
              "application/json",
            "x-api-key": apikey.xapikey,
            token: id_token
          }
        }
      )
        .then(response => response.json())
        .then(() => {
          setAgentListCall(true);
          document.getElementById("modalCloseBtn").click();
        })
        .catch(error => {
          console.error(error);
        });
      // async function saveRule() {
      //   const request = await axios.post(config.agentRule, {
      //     headers: {
      //       "Content-Type": "application/json",
      //       "x-api-key": apikey.xapikey,
      //       token: id_token
      //     },
      //     body: JSON.stringify(saveData)
      //   });
      //   setSave(false);
      // }
      // saveRule();
    }

    if (update === true) {
      setUpdate(false);

      fetch(config.baseURL + config.agentRule, {
        method: "PUT",
        body: JSON.stringify(updateData),
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      })
        .then(response => response.json())
        .then(() => {
          setAgentListCall(true);
          document.getElementById("modalCloseBtn").click();
        })
        .catch(error => {
          console.error(error);
        });
      // async function updateRule() {
      //   const request = await axios.put(config.agentRule, {
      //     headers: {
      //       "Content-Type": "application/json",
      //       "x-api-key": apikey.xapikey,
      //       token: id_token
      //     },
      //     body: JSON.stringify(updateData)
      //   });
      //   setUpdate(false);
      // }
      // updateRule();
    }

    if (deleteClick === true) {
      setDeleteClick(false);

      fetch(
        config.baseURL + config.deleteAgentRule + deleteExistingRule.rule_id,
        {
          method: "DELETE",
          // body: JSON.stringify(deleteExistingRule),
          headers: {
            "Content-Type": "application/json",
            "x-api-key": apikey.xapikey,
            token: id_token
          }
        }
      )
        .then(response => response.json())
        .then(() => {
          setAgentListCall(true);
        })
        .catch(error => {
          console.error(error);
        });
      // async function deleteRule() {
      //   const request = await axios.delete(
      //     config.deleteAgentRule + deleteExistingRule.rule_id,
      //     {
      //       headers: {
      //         "Content-Type": "application/json",
      //         "x-api-key": apikey.xapikey,
      //         token: id_token
      //       },
      //       body: JSON.stringify(deleteExistingRule)
      //     }
      //   );
      //   setDeleteClick(false);
      // }
      // deleteRule();
    }
  }, [
    agentListCall,
    agentList,
    channelList,
    save,
    update,
    deleteClick,
    saveData,
    updateData,
    id_token,
    deleteExistingRule
  ]);


  const GetActionFormat = (cell, row) => {
    return (
      <div>
        <button
          type="button"
          className="btn"
          size="sm"
          onClick={() => editRule(cell, row)}
        >
          Edit
        </button>
        <button
          type="button"
          className="btn"
          size="sm"
          onClick={() => deleteRule(cell, row)}
        >
          Delete
        </button>
      </div>
    );
  };
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "commission_date",
      text: "Commission Date"
    },
    {
      dataField: "channel_id",
      text: "Channel Id"
    },
    {
      dataField: "channel_name",
      text: "Channel Name"
    },
    {
      dataField: "channel_category",
      text: "Channel Category"
    },
    {
      dataField: "referral_channel_name",
      text: "Referral Channel Name"
    },
    {
      dataField: "application_fee",
      text: "Application Fee"
    },
    {
      dataField: "monthly_share",
      text: "Monthly Share"
    },
    {
      dataField: "monthly_dues",
      text: "Monthly Dues"
    },
    {
      dataField: "",
      text: "Options",
      formatter: GetActionFormat
    }
  ];


  const addRule = () => {
    document.getElementById("modalAddBtn").click();
    setTimeout(() => {
      setIsEdit(0);
      setSaveValue(0);
      setCommissionDate(currentDate);
      setChannelId("");
      setChannelName("");
      setChannelCategory("");
      setReferralChannel("");
      setApplicationFee("");
      setMonthlyShare("");
      setMontyhlyDue("");
    }, 1);
    
  }

  const editRule = (cell, row) => {
    document.getElementById("modalAddBtn").click();
    setTimeout(() => {
      setIsEdit(1)
      setSaveValue(1);
      setCommissionDate(row.commission_date);
      setChannelId(row.channel_id);
      setChannelName(row.channel_name);
      setChannelCategory(row.channel_category);
      setReferralChannel(row.referral_channel_name);
      setApplicationFee(row.application_fee);
      setMonthlyShare(row.monthly_share);
      setMontyhlyDue(row.monthly_dues);
      setRuleId(row.rule_id)
    }, 1);
  };

  const deleteRule = (cell, row) => {
    var bodyData = {
      rule_id: row.rule_id
    };
    setDeleteClick(true)
    setDeleteExistingRule(bodyData);
  };


  const dateChange = e => {
    e.preventDefault();
    setCommissionDate(e.target.value);
  };

  const channelIDChange = e => {
    e.preventDefault();
    setChannelId(e.target.value);
  };

  const channelSelect = e => {
    e.preventDefault();
    setChannelName(e.target.value);
  };

  const channelCategoryChange = e => {
    e.preventDefault();
    setChannelCategory(e.target.value);
  };

  const referralChannelChange = e => {
    e.preventDefault();
    setReferralChannel(e.target.value);
  };

  const applicationFeeChange = e => {
    e.preventDefault();
    setApplicationFee(e.target.value);
  };

  const monthlyShareChange = e => {
    e.preventDefault();
    setMonthlyShare(e.target.value);
  };

  const monthlyDueChange = e => {
    e.preventDefault();
    setMontyhlyDue(e.target.value);
  };

  const addNewRule = () => {
    setSave(true)
    var bodyData = {
      commission_date: commissionDate,
      channel_id: channelId,
      channel_name: channelName,
      channel_category: channelCategory,
      referral_channel_name: referralChannel,
      application_fee: applicationFee,
      monthly_share: monthlyShare,
      monthly_dues: montyhlyDue
    };
    setSaveData(bodyData);

  };

  const editExistingRule = () => {
    setUpdate(true);

    var bodyData = {
      application_fee: applicationFee,
      monthly_share: monthlyShare,
      monthly_dues: montyhlyDue,
      rule_id: ruleId
    };
    setUpdateData(bodyData);
  };
  

  return (
    <ContentWrapper>
      <button
        data-toggle="modal"
        id="modalAddBtn"
        data-target="#modalAdd"
        onClick={addRule}
      >
        Add New
      </button>

      <div
        className="modal fade"
        id="modalAdd"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modalHeading">
              {saveValue === 0 ? (
                <span>Add New Rule</span>
              ) : (
                <span>Update Rule</span>
              )}
            </div>
            <button
              data-dismiss="modal"
              id="modalCloseBtn"
              style={{ display: "block" }}
            >
              Close
            </button>
            <div className="modalContentDiv">
              <div className="modalContentHead">Commision Date</div>
              <input
                type="date"
                id="addName"
                readOnly={isEdit === 1 ? true : false}
                value={commissionDate}
                onChange={dateChange}
                className="modalContentInput"
              />
            </div>
            <div className="modalContentDiv">
              <div className="modalContentHead">Channel ID</div>
              <input
                type="text"
                id="addDesc"
                readOnly={isEdit === 1 ? true : false}
                value={channelId}
                onChange={channelIDChange}
                className="modalContentInput"
              />
            </div>
            <div className="modalContentDiv">
              <div className="modalContentHead">Channel Name</div>
              <select
                onChange={channelSelect}
                // className="filterByDateButton filterHome"
                disabled={isEdit === 1 ? true : false}
                style={{ textAlign: "center" }}
              >
                <option value="select"> Select Channel </option>
                {channelList.map(item => (
                  <option key={item.channel_name} value={item.channel_name}>
                    {item.channel_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="modalContentDiv">
              <div className="modalContentHead">Channel Category</div>
              <input
                type="text"
                id="addPrice"
                readOnly={isEdit === 1 ? true : false}
                value={channelCategory}
                onChange={channelCategoryChange}
                className="modalContentInput"
              />
            </div>
            <div className="modalContentDiv">
              <div className="modalContentHead">Referral Channel Name</div>
              <input
                type="text"
                id="addUnit"
                readOnly={isEdit === 1 ? true : false}
                value={referralChannel}
                onChange={referralChannelChange}
                className="modalContentInput"
              />
            </div>
            <div className="modalContentDiv">
              <div className="modalContentHead">Application Fee</div>
              <input
                type="text"
                id="appFee"
                value={applicationFee}
                onChange={applicationFeeChange}
                className="modalContentInput"
              />
            </div>
            <div className="modalContentDiv">
              <div className="modalContentHead">Monthly Share</div>
              <input
                type="text"
                id="monthlyShare"
                value={monthlyShare}
                onChange={monthlyShareChange}
                className="modalContentInput"
              />
            </div>
            <div className="modalContentDiv">
              <div className="modalContentHead">Monthly Due</div>
              <input
                type="text"
                id="monthlyDue"
                value={montyhlyDue}
                onChange={monthlyDueChange}
                className="modalContentInput"
              />
            </div>

            <div className="modalContentDiv">
              {saveValue === 0 ? (
                <button onClick={addNewRule} className="modalBtn">
                  Save
                </button>
              ) : (
                <button onClick={editExistingRule} className="modalBtn">
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="tableBody clearfix">
        {load === true && <div className="loader" />}
        {load === true && (
          <div style={{ textAlign: "center" }}>Loading List</div>
        )}

        {load === false && (
          
          <div className="tableInnerDiv">
          <ToolkitProvider
            id="table-to-xls"
            key="id"
            // ref={n => (this.node = n)}
            keyField="rule_id"
            data={agentList}
            columns={columns}
            // filter={ filterFactory() }
            // pagination={ paginationFactory() }
            // selectRow={ { mode: 'checkbox', clickToSelect: true } }
            // expandRow={ expandRow }
            search
            exportCSV
          >
            {props => (
              <div>
                <ExportCSVButton {...props.csvProps}>
                  Export CSV
                </ExportCSVButton>
                <SearchBar {...props.searchProps} />
                
                <BootstrapTable
                  {...props.baseProps}
                  pagination={paginationFactory()}
                  // expandRow={expandRow}
                />
              </div>
            )}
          </ToolkitProvider>
          </div>
        )}
      </div>
    </ContentWrapper>
  );
};

export default AdminRules;
