module.exports = {
  aws_cognito_region: "us-east-2", // (required) - Region where Amazon Cognito project was created
  aws_user_pools_id: "us-east-2_t2FNuj3XS", // (optional) - Amazon Cognito User Pool ID
  aws_user_pools_web_client_id: "3dqtf4c0g43c047gkrsadgutp6",
  aws_mandatory_sign_in: "enable"
};


  // aws_user_pools_id: "us-east-2_t2FNuj3XS", // (optional) - Amazon Cognito User Pool ID
  // aws_user_pools_web_client_id: "3dqtf4c0g43c047gkrsadgutp6",
