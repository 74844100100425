import React from "react";
import ContentWrapper from "../../Common/ContentWrapper";

const RuleEditor = () => {
  return (
    <ContentWrapper>
      <iframe
        id="ruleEditor"
        src="https://d3ngjceipurvvw.cloudfront.net/"
        style={{ width: "100%", height: "85vh" }}
      ></iframe>
    </ContentWrapper>
  );
};

export default RuleEditor;
