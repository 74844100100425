import React from "react";
import Wrapper from "../../Common/Wrapper";
import Helmet from "react-helmet";


const ContentWrapper = ({ children }) => {

  return (
    <Wrapper>
      <div className="mainBody"> {children}</div>
      <Helmet bodyAttributes={{ style: "background-color : #eae8db" }} />
    </Wrapper>
  );
};

export default ContentWrapper;
