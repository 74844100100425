import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";
import { useSelector, useDispatch } from "react-redux";
import {
  setChannels,
  setNeeds
} from "../../../../redux/actions/productActions";

import { ExportToCsv } from "export-to-csv";
import MaterialTable ,{ MTableToolbar }from 'material-table';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import buttonSymbol  from "../../../../assets/images/calender.png"

const Needs = () => {
 
  const now = new Date();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now
    .getDate()
    .toString()
    .padStart(2, "0");
  const tempToDate = `${now.getFullYear()}-${month}-${day}`;

  const [load, setLoad] = useState(false);
  const [agentList, setAgentList] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const [agentListCall, setAgentListCall] = useState(true);
  const [selectedChannel, setSelectedChannel] = useState("All");
  const [fromDate, setFromDate] = useState("2011-06-09");
  const [toDate, setToDate] = useState(tempToDate);
  const [agentListDownloadCall, setAgentListDownloadCall] = useState(false);

  const pageNo = 1;
  const sizePerPage= 10;
 

  const [empId, setEmpId] = useState(true);
  const [empList, setEmpList] = useState([]);
  const [empClick, setEmpClick] = useState(false);
  const [empLoad, setEmpLoad] = useState(false);
 const pii= JSON.parse(localStorage.getItem('PII'))

  let username = localStorage.getItem(
    "CognitoIdentityServiceProvider.3dqtf4c0g43c047gkrsadgutp6.LastAuthUser"
  );
  let id_token = localStorage.getItem(
    "CognitoIdentityServiceProvider.3dqtf4c0g43c047gkrsadgutp6." +
    username +
    ".idToken"
  );
  const dataList = useSelector(state => state);
  const dispatch = useDispatch();
   const  fetchList=async (page_no,page_size) =>{
    const request = await axios.get(
      config.regulatorNeeds +
      "page=" +
      page_no +
      "&size=" +
      page_size +
      "&channel=" +
      selectedChannel+
       "&from_date=" +
      fromDate +
      "&to_date=" +
      toDate ,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
    setAgentList(request.data[0].payload);
 

    setLoad(false);
    if (dataList.channels.needs.length === 0) {
      dispatch(setNeeds(request.data[0].payload));
    }
    const response={data:request.data[0].payload,total_count: request.data[0].meta_info[0].total_count}
    return response
  }


        async function downloadList() {
        const request = await axios.get(config.regulatorNeedExport, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": apikey.xapikey,
            token: id_token
          }
        });
        // setAgentList(request.data);
        csvExporter.generateCsv(request.data);
      }
         async function fetchChannelList() {
          const request = await axios.get(config.channelList, {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": apikey.xapikey,
              token: id_token
            }
          });
          dispatch(setChannels(request.data));
          setChannelList(request.data);
        }
         async function fetchEmp() {
        const request = await axios.get(config.empDetails + empId, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": apikey.xapikey,
            token: id_token
          }
        });
        setEmpLoad(false);
        if (request.data !== null) {
          setEmpList(request.data);
        }
      }
  useEffect(() => {

    setChannelList(dataList.channels.channels);
    if (agentListCall === true) {
      setAgentListCall(false);
      // if (dataList.channels.needs.length > 0 && agentList[0] === 'null') {

      //   setAgentList(dataList.channels.needs);
      // } else {

      setLoad(true);
      setAgentListCall(false);
     
      fetchList(pageNo,sizePerPage);
      // }
    }

    if (agentListDownloadCall === true) {
      setAgentListDownloadCall(false);

      downloadList();
    }

    if (channelList.length === 0) {
      if (dataList.channels.channels.length === 0) {

        fetchChannelList();
      }
    }

    if (empClick === true) {
      setEmpClick(false);
      setEmpLoad(true);
     
      fetchEmp();
    }
  }, [agentListCall, agentListDownloadCall, agentList, channelList, empClick]);

  const convert = str => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const handleEvent = (event, picker) => {
    setFromDate(convert(picker.startDate._d));
    setToDate(convert(picker.endDate._d));
    setAgentListCall(true);
  };

  const channelSelect = e => {
    e.preventDefault();
    setAgentListCall(true);
    setSelectedChannel(e.target.value);
  };



  const dowloadfile = () => {
    setAgentListDownloadCall(true);
  };

  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: "need_report"
  };
  const renderTableData=(data) =>{
    return data.map((data, index) => {
       const { empi, amount, paid_at } = data //destructuring
       return (
          <tr key={index}>
             <td className="detailPaneltd">{paid_at}</td>
             <td className="detailPaneltd">{GetActionEmpIdFormat(empi)}</td>
             <td className="detailPaneltd">{amount}</td>
             
          </tr>
       )
    })
  }
  const empIdClick = id => {
    setEmpList([]);
    setEmpClick(true);
    setEmpId(id);
    document.getElementById("modalEmpBtn").click();
  };

  const GetActionEmpIdFormat = (need_empi) => {
    return (
      <div>
        <label
          className="empName"
          size="sm"
          onClick={() => empIdClick(need_empi)}
        >
          {need_empi}
        </label>
      </div>
    );
  };






  const csvExporter = new ExportToCsv(options);


  const columns = [
    {
      field: "requested_at",
      title: "Date of Receipt",
      headerStyle: { minWidth: "100px" }
    },
    {
      field: "need_id",
      title: "Needs ID"
    },
    {
      field: "need_empi",
      title: "EMPI",
      render: rowData => GetActionEmpIdFormat(rowData.need_empi)
    },
    {
      field: "member_id",
      title: "Member ID"
    },
    // {
    //   field: "",
    //   title: "Contributions",
    //   render: rowData => GetActionEmpIdFormat(rowData.need_empi)
    
    // },
    {
      field: "algorithm_case",
      title: "Algorithm Case"
    },
    {
      field: "status",
      title: "Status"
    },
    {
      field: "need_amount",
      title: "Needs Amount"
    }
  ];

   console.log(agentList)
  return (
    <ContentWrapper>
      <span className="pageheading"> Needs </span>

      <button
        data-toggle="modal"
        id="modalEmpBtn"
        data-target="#modalEmp"
        style={{ display: "none" }}
      >
        Emp
      </button>

      <div
        className="modal fade"
        id="modalEmp"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered dialogEmpWidth">
          <div className="modal-content">
            <div className="modalHeading">Member Info</div>
            {/* <button
              data-dismiss="modal"
              id="modalCloseBtn"
              style={{ display: "block" }}
              className="modalCloseBtn"
            >
              Close
            </button> */}
   

            <div className="modalContentDiv">
              {empLoad === true && <div className="loader" />}
              {empLoad === true && (
                <div style={{ textAlign: "center" }}>Loading Member Info</div>
              )}
              {empLoad === false && (
                <div className="empDiv">
                  <div className="empDataDiv">
                    <span className="empQus">Name :</span>{" "}
                    {pii == true ? (<span style={{ backgroundColor: "black", color: "black", cursor: "not-allowed" }}>
                      {empList.first_name}&nbsp; {empList.last_name}
                    </span>) :
                      (<span >
                        {empList.first_name}&nbsp; {empList.last_name}
                      </span>)}
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">Emp Id :</span>{" "}
                    <span>{empList.empi}</span>
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">Gender :</span>{" "}
                    {pii == true ? (<span style={{ backgroundColor: "black", color: "black", cursor: "not-allowed" }}>{empList.gender}</span>) :
                      (<span >{empList.gender}</span>)
                    }
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">Plan Id :</span>{" "}
                    <span>{empList.plan_id}</span>
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">State Code :</span>{" "}
                    <span>{empList.state_code}</span>
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">Benefit Begin :</span>{" "}
                    <span>{empList.benefit_begin}</span>
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">Benefit End :</span>{" "}
                    <span>{empList.benefit_end}</span>
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">Paid Through Date:</span>{" "}
                    <span>{empList.paid_through_date}</span>
                  </div>
                  <hr />
                </div>
              )}
            </div>
            <div className="modalFooter">
              
              <button
                data-dismiss="modal"
                id="modalCloseBtn2"
                style={{ display: "block" }}
                className="modalFooterDoneBtn"
              >
                Done
              </button>
              <button
                data-dismiss="modal"
                id="modalCloseBtn1"
                style={{ display: "block" }}
                className="modalFooterCloseBtn"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="tableBody clearfix">
        {load === true && <div className="loader" />}
        {load === true && (
          <div style={{ textAlign: "center" }}>Loading List</div>
        )}
        {load === false && (
           <MaterialTable
           // other props
           components={{
             
             Toolbar: props => (
                 <div style={{ backgroundColor: '#f6f6e6' }}>
                     <MTableToolbar {...props} />
                     <select
         onChange={channelSelect}
         className="filterByDateButton channelFilterPosition searchInput"
         style={{ textAlign: "center" }}
 
       >
         <option value="All"> Select Channel </option>
         {channelList.map(item => (
           <option key={item.channel_name} value={item.channel_name}>
             {item.channel_abbr}
           </option>
         ))}
       </select>
 
       <DateRangePicker
         onApply={handleEvent}
         parentEl={"#reportDRPContainerWallet"}
         ranges={{
           Today: [moment(), moment()],
           Yesterday: [
             moment().subtract(1, "days"),
             moment().subtract(1, "days")
           ],
           "Last 7 Days": [moment().subtract(6, "days"), moment()],
           "Last 30 Days": [moment().subtract(29, "days"), moment()],
           "This Month": [moment().startOf("month"), moment().endOf("month")],
           "Last Month": [
             moment()
               .subtract(1, "month")
               .startOf("month"),
             moment()
               .subtract(1, "month")
               .endOf("month")
           ],
           "Last Year": [moment().subtract(366, "days"), moment()],
           "Last 5 Years": [moment().subtract(1826, "days"), moment()]
         }}
         alwaysShowCalendars={true}
       >
         <button className="filterByDateButton dateFilterPosition">
           <img alt="" src={buttonSymbol.default} className="buttonSymbol" />
           Filter by Date
         </button>
       </DateRangePicker>
                 </div>
             )
         }}
 
           columns={columns}
          
            data={query =>
             
              new Promise((resolve, reject) => {
                console.log(query)
                  // setPageNo()
                  // setSizePerPage(query.pageSize)
                  fetchList(query.page +1,query.pageSize)
                  .then(result=>{

                    resolve({
                      data: result.data,// your data array
                      page:query.page , // current page number
                      totalCount: result.total_count// total row number
                  });
                  })
                 
              })
            
          }
           options={{pageSize:10,searchFieldAlignment:"left",searchFieldStyle:{
             backgroundColor:"white",
             
           }}}
           detailPanel={rowData => {
            return (
              <div className="detailPanel">
                 <div className="detailPanelTableHeader"> Contributions</div>
              <table className="detailPanelTable">
              <tr className="detailPaneltr">
              <th className="detailPanelth" scope="col">Paid At</th>
              <th className="detailPanelth" scope="col">EMPI</th>
              <th className="detailPanelth" scope="col">AMOUNT</th>
              </tr>
              <tbody className="detailPaneltd">
                {renderTableData(rowData.contributor)}
              </tbody>
                </table>
                </div>)}}
           title={false}
           actions={[
             {
               icon: 'save_alt',
               tooltip: 'Export Data',
               isFreeAction: true,
               iconProps:{style:{backgroundColor:"#eb5757",width:"35px",height:"35px",borderRadius:"20px",paddingTop:"6px",color:"white"}},
               onClick: () => dowloadfile(),
             },
             {
               icon: 'refresh',
               tooltip: 'Refresh Data',
               isFreeAction: true,
               iconProps:{style:{backgroundColor:"#41b5c2",width:"35px",height:"35px",borderRadius:"20px",paddingTop:"6px",color:"white"}},
               onClick: () => fetchList(pageNo,sizePerPage),
             }
           ]}
         
           />
        )}
      </div>
    </ContentWrapper>
  );
};



export default Needs;
