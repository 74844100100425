import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";
import { useSelector, useDispatch } from "react-redux";
import { setChannels, setSharewalletBalance } from '../../../../redux/actions/productActions';
import MaterialTable ,{ MTableToolbar }from 'material-table';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from 'moment';
import { ExportToCsv } from "export-to-csv";
import buttonSymbol from "../../../../assets/images/calender.png"


const ShareWalletBalance = () => {
  const now = new Date();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now
    .getDate()
    .toString()
    .padStart(2, "0");
  const tempToDate = `${now.getFullYear()}-${month}-${day}`;

  const [load, setLoad] = useState(false);
  const [agentList, setAgentList] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const [agentListCall, setAgentListCall] = useState(true);
  const [selectedChannel, setSelectedChannel] = useState("All");
  const [fromDate, setFromDate] = useState("2011-06-09");
  const [toDate, setToDate] = useState(tempToDate);

  let username = localStorage.getItem(
    "CognitoIdentityServiceProvider.3dqtf4c0g43c047gkrsadgutp6.LastAuthUser"
  );
  let id_token = localStorage.getItem(
    "CognitoIdentityServiceProvider.3dqtf4c0g43c047gkrsadgutp6." +
      username +
      ".idToken"
  );

  const dataList = useSelector((state) => state);
  const dispatch = useDispatch();
  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: "ClaimsWallet"
  };
  const csvExporter = new ExportToCsv(options);
  async function fetchList() {
    setLoad(true);
    const request = await axios.get(
      config.regulatorPayableShareWalletBalance +
      "from_date=" +
      fromDate +
      "&to_date=" +
      toDate +
      "&channel=" +
      selectedChannel,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
    if (dataList.channels.sharewallet.length === 0) {
      dispatch(setSharewalletBalance(request.data));
    }
    setAgentList(request.data);
    console.log(agentList)
    setLoad(false);
  }
  async function downloadList() {
  
    const request = await axios.get(
      config.regulatorPayableShareWalletBalance +
      "from_date=" +
      fromDate +
      "&to_date=" +
      toDate +
      "&channel=" +
      selectedChannel,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
  
    csvExporter.generateCsv(request.data);
  }
      async function fetchChannelList() {
          const request = await axios.get(config.channelList, {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": apikey.xapikey,
              token: id_token
            }
          });
          dispatch(setChannels(request.data));
          setChannelList(request.data);
        }
  useEffect(() => {
    if (agentListCall === true) {
      setAgentListCall(false);
      if(dataList.channels.sharewallet.length > 0 && (agentList[0] === 'null' || agentList[0] === undefined)) {
        setAgentList(dataList.channels.sharewallet);
      } else {
        setLoad(true);
        setAgentListCall(false);

        fetchList();
      }

    }
    setChannelList(dataList.channels.channels);

    if (channelList.length === 0) {
      if (dataList.channels.channels.length === 0) {
    
        fetchChannelList();
      }
    }
  }, [
    agentListCall,
    agentList,
    channelList
  ]);


  const convert = str => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const handleEvent = (event, picker) => {
    setFromDate(convert(picker.startDate._d));
    setToDate(convert(picker.endDate._d));
    setAgentListCall(true);
  };

  const channelSelect = e => {
    e.preventDefault();
    setAgentListCall(true);
    setSelectedChannel(e.target.value);
  };

 
  const columns = [
    {
      field: "date",
      title: "Date"
    },
    {
      field: "sharewallet",
      title: "Claims Wallet"
    }
  ];



  return (
    <ContentWrapper>
      <span className="pageheading">Claims Wallet Balance</span>
      

      <div className="tableBody clearfix">
        {load === true && <div className="loader" />}
        {load === true && (
          <div style={{ textAlign: "center" }}>Loading List</div>
        )}

        {load === false && (
       
            <MaterialTable
            // other props
            components={{
              
              Toolbar: props => (
                  <div style={{ backgroundColor: '#f6f6e6' }}>
                      <MTableToolbar {...props} />
                      <select
          onChange={channelSelect}
          className="filterByDateButton channelFilterPosition searchInput"
          style={{ textAlign: "center" }}

        >
          <option value="All"> Select Channel </option>
          {channelList.map(item => (
            <option key={item.channel_name} value={item.channel_name}>
              {item.channel_abbr}
            </option>
          ))}
        </select>

        <DateRangePicker
          onApply={handleEvent}
          parentEl={"#reportDRPContainerWallet"}
          ranges={{
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days")
            ],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
              moment()
                .subtract(1, "month")
                .startOf("month"),
              moment()
                .subtract(1, "month")
                .endOf("month")
            ],
            "Last Year": [moment().subtract(366, "days"), moment()],
            "Last 5 Years": [moment().subtract(1826, "days"), moment()]
          }}
          alwaysShowCalendars={true}
        >
          <button className="filterByDateButton dateFilterPosition">
            <img alt="" src={buttonSymbol.default} className="buttonSymbol" />
            Filter by Date
          </button>
        </DateRangePicker>
                  </div>
              )
          }}

          columns={columns}
          data={agentList}
          options={{pageSize:10,searchFieldAlignment:"left",searchFieldStyle:{
            backgroundColor:"white",
            
          }}}
          title={false}
          actions={[
            {
              icon: 'save_alt',
              tooltip: 'Export Data',
              isFreeAction: true,
              iconProps:{style:{backgroundColor:"#eb5757",width:"35px",height:"35px",borderRadius:"20px",paddingTop:"6px",color:"white"}},
              onClick: () => downloadList(),
            },
            {
              icon: 'refresh',
              tooltip: 'Refresh Data',
              isFreeAction: true,
              iconProps:{style:{backgroundColor:"#41b5c2",width:"35px",height:"35px",borderRadius:"20px",paddingTop:"6px",color:"white"}},
              onClick: () => fetchList(),

            }
          ]}
        
          />
         
     
     
        )}
      </div>
    </ContentWrapper>
  );
};

export default ShareWalletBalance;

