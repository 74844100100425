import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";
import Moment from 'react-moment';

import { useSelector, useDispatch } from "react-redux";
import { setShareplusSummary } from '../../../../redux/actions/productActions';

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "bootstrap/dist/css/bootstrap.css";

const ShareplusSummary = () => {
  const [load, setLoad] = useState(true);
  // const [agentList, setAgentList] = useState({
  //   Enrolment_date_first_member: "2020-01-08",
  //   Enrolment_date_last_member: "2022-02-11",
  //   total_number_of_valid_AC_members: 5912,
  //   total_number_of_invalid_AC_members: 0,
  //   total_number_of_valid_TE_members: 22534,
  //   total_number_of_invalid_TE_members: 0,
  //   total_number_of_members: 28446,
  //   total_households_active: 4409,
  //   total_households_inactive: 15838,
  //   total_households: 20128,
  //   no_of_uploaded_transactions: 95201,
  //   Last_transaction_date: "2022-02-14",
  //   Last_transaction_imported_date: "2022-02-16 06:14:11",
  //   transaction_sharewallet: 54343,
  //   transaction_adminwallet: 40858,
  //   no_of_transaction_after_merging: 95201,
  //   encumbered_sharewallet: 3194938.04,
  //   unencumbered_sharewallet: 3482509.16,
  //   total_sharewallet: 6677447.2,
  //   admin_month_one: 1788068.25,
  //   admin_month_two: 1485783.04,
  //   encumbered_opsreserve: 970891.62,
  //   unencumbered_opsreserve: 2302959.67,
  //   total_opsreserve: 3273851.29,
  //   total_refund: 4605980.81,
  //   total_chargeback: 414988.59,
  //   total_contrib_encumbered: 4165829.66,
  //   total_contrib_unencumbered: 5785468.83,
  //   encumbered_gross: 4165829.66,
  //   unencumbered_gross: 4165829.66,
  //   Last_claim_date: "2022-02-11",
  //   Last_claim_imported_date: "2022-02-16 10:47:23",
  //   no_of_valid_needs: 12018,
  //   total_number_of_needs: 36952,
  //   paid_need_amount: 2826626.67
  // });
  const [agentList, setAgentList] = useState([]);
  const [today, setToday] = useState(null);
  const [agentListCall, setAgentListCall] = useState(true);
 const pii= JSON.parse(localStorage.getItem('PII'))
  let username = localStorage.getItem(
    "CognitoIdentityServiceProvider.3dqtf4c0g43c047gkrsadgutp6.LastAuthUser"
  );
  let id_token = localStorage.getItem(
    "CognitoIdentityServiceProvider.3dqtf4c0g43c047gkrsadgutp6." +
    username +
    ".idToken"
  );
  const dataList = useSelector((state) => state);
  const dispatch = useDispatch();



    // useEffect(() => {
    //   setLoad(false);
    
    //     const DATE_OPTIONS = {
    //       weekday: "short",
    //       year: "numeric",
    //       month: "short",
    //       day: "numeric"
    //     };
    //     const date = new Date().toLocaleDateString("en-US", DATE_OPTIONS);
    //     setToday(date);

    //     setAgentList(dataList.channels.summary);
    //     if (dataList.channels.summary !== []) {
    //       setLoad(false);
    //     }

    //     if (dataList.channels.summary.length === 0) {
    //       // if (agentListCall === true) {
    //       setLoad(true);
    //       setAgentListCall(false);
    //       console.log(agentListCall)
    //       // if (agentListCall === true) {
    //       async function fetchList() {
    //         const request = await axios.get(
    //           config.regulatorPayableShareplusSummary,
    //           {
    //             headers: {
    //               "Content-Type": "application/json",
    //               "x-api-key": apikey.xapikey,
    //               token: id_token
    //             }
    //           }
    //         );
    //         dispatch(setShareplusSummary(request.data));
    //         setAgentList(request.data);
    //         setLoad(false);
    //       }
    //       fetchList();
    //     // }
    //   }
    //   }, [agentListCall, agentList]);

      useEffect(() => {
        const DATE_OPTIONS = {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric"
        };
        const date = new Date().toLocaleDateString("en-US", DATE_OPTIONS);
        setToday(date);
        setAgentList(dataList.channels.summary);
        if (dataList.channels.summary !== []) {
          setLoad(false);
        }
          // console.log(agentListCall);

        // if (agentListCall === true) {
          setAgentListCall(false);
          // console.log(agentListCall);
          if (dataList.channels.summary.length === 0) {
            setLoad(true);

            async function fetchList() {
              console.log(agentListCall);

              const request = await axios.get(
                config.regulatorPayableShareplusSummary,
                {
                  headers: {
                    "Content-Type": "application/json",
                    "x-api-key": apikey.xapikey,
                    token: id_token
                  }
                }
              );
              dispatch(setShareplusSummary(request.data));
              setAgentList(request.data);
              // console.log(agentList);
              setLoad(false);
            }

            fetchList();
          }
        // }
      }, [agentListCall,agentList]);
  return (
    <ContentWrapper>
      <span className="pageheading">Summary</span>

      {agentList !== [] && (
        <div
          className="tableBody clearfix"
          style={{ paddingRight: "25px", paddingLeft: "25px" }}
        >
          <div className="clearfix">
            <div className="pageHead">
              <div style={{ float: "left", paddingTop: "20px" }}></div>
              <div style={{ float: "right", paddingTop: "20px" }}>
                <span className="reportpagedate">
                  Date: <strong> {today} </strong>
                </span>
                {pii ? (
                  <span
                    className="reportpagedate"
                    style={{
                      backgroundColor: "black",
                      color: "black",
                      cursor: "not-allowed",
                      userSelect: "none"
                    }}
                  >
                    Customer: <strong>Universal Health Fellowship</strong>
                  </span>
                ) : (
                  <span className="reportpagedate">
                    Customer: <strong>Universal Health Fellowship</strong>
                  </span>
                )}
              </div>
            </div>
          </div>
          {load === true && <div className="loader" />}
          {load === true && (
            <div style={{ textAlign: "center" }}>Loading List</div>
          )}
          {!load && agentList !== [] && (
            <div style={{ marginBottom: "5px" }}>
              <div>
                <table className="table" style={{ border: "1px solid #ddd" }}>
                  <tbody>
                    <tr style={{ background: "#D4D5D6" }}>
                      <td
                        colSpan="2"
                        style={{
                          width: "100%",
                          color: "black",
                          fontSize: "16px"
                        }}
                      >
                        Members
                      </td>
                    </tr>
                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        style={{
                          width: "50%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        {" "}
                        Enrollment date of first member:{" "}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        <Moment format="YYYY/MM/DD">
                          {agentList.Enrolment_date_first_member}
                        </Moment>
                      </td>
                    </tr>
                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        style={{
                          width: "50%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        {" "}
                        Enrollment date of last member:
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        <Moment format="YYYY/MM/DD">
                          {agentList.Enrolment_date_last_member}
                        </Moment>
                      </td>
                    </tr>
                    <tr style={{ background: "#F4F5F6" }}>
                      <td
                        style={{
                          width: "50%",
                          fontWeight: "bold",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Total members:
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        {agentList.total_number_of_members}
                      </td>
                    </tr>
                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        style={{
                          width: "50%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Total encumbered members:
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        {agentList.total_number_of_valid_AC_members}
                      </td>
                    </tr>
                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        style={{
                          width: "50%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Total unencumbered members:
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        {agentList.total_number_of_valid_TE_members}
                      </td>
                    </tr>
                    <tr style={{ background: "#F4F5F6" }}>
                      <td
                        style={{
                          width: "50%",
                          fontWeight: "bold",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Total household:
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        {agentList.total_households_active +
                          agentList.total_households_inactive}
                      </td>
                    </tr>

                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        style={{
                          width: "50%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Total encumbered household:
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        {agentList.total_households_active}
                      </td>
                    </tr>
                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        style={{
                          width: "50%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Total unencumbered household:
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        {agentList.total_households_inactive}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style={{ marginTop: "20px" }}>
                <table className="table" style={{ border: "1px solid #ddd" }}>
                  <tbody>
                    <tr style={{ background: "#D4D5D6" }}>
                      <td
                        colSpan="2"
                        style={{
                          width: "100%",
                          color: "black",
                          fontSize: "16px"
                        }}
                      >
                        Transactions
                      </td>
                    </tr>

                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        style={{
                          width: "50%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Last transaction import date:
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        <Moment format="YYYY/MM/DD">
                          {agentList.Last_transaction_imported_date}
                        </Moment>
                      </td>
                    </tr>
                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        style={{
                          width: "50%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Last transaction date:
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        <Moment format="YYYY/MM/DD">
                          {agentList.Last_transaction_date}
                        </Moment>
                      </td>
                    </tr>
                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        style={{
                          width: "50%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Total number of uploaded transactions:{" "}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        {agentList.no_of_uploaded_transactions}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style={{ marginTop: "20px" }}>
                <table className="table" style={{ border: "1px solid #ddd" }}>
                  <tbody>
                    <tr style={{ background: "#D4D5D6" }}>
                      <td
                        colSpan="2"
                        style={{
                          width: "100%",
                          color: "black",
                          fontSize: "16px"
                        }}
                      >
                        Account Balances
                      </td>
                    </tr>
                    <tr style={{ background: "#F4F5F6" }}>
                      <td
                        style={{
                          width: "50%",
                          fontWeight: "bold",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Total ClaimsWallet Contributions:{" "}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        {/* {agentList.total_sharewallet.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD"
                        })} */}
                        {pii ? (
                          <span>
                            $
                            <span
                              style={{
                                backgroundColor: "black",
                                color: "black",
                                cursor: "not-allowed",
                                userSelect: "none"
                              }}
                            >
                              {agentList.total_sharewallet
                                .toString()
                                .slice(1, 4)}
                            </span>
                            <span>
                              {agentList.total_sharewallet.toString().slice(4)}
                            </span>
                          </span>
                        ) : (
                          <span>{agentList.total_sharewallet}</span>
                        )}
                        {/* {agentList.total_sharewallet} */}
                        {/* <br />$
                        <span
                          style={{
                            backgroundColor: "black",
                            color: "black",
                            cursor: "not-allowed",
                            userSelect: "none"
                          }}
                        >
                          {agentList.total_sharewallet.toString().slice(0, 3)}
                        </span>
                        {agentList.total_sharewallet.toString().slice(3)} */}
                        {/* {agentList.total_sharewallet.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD"
                        })} */}
                      </td>
                    </tr>
                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        colSpan="2"
                        style={{
                          width: "50%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Breakups by encumbered and unencumbered accounts
                      </td>
                    </tr>
                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        style={{
                          width: "50%",
                          paddingLeft: "5%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Contributions in encumbered accounts:{" "}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        {/* {agentList.encumbered_sharewallet.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD"
                          }
                        )} */}
                        {pii ? (
                          <span>
                            $
                            <span
                              style={{
                                backgroundColor: "black",
                                color: "black",
                                cursor: "not-allowed",
                                userSelect: "none"
                              }}
                            >
                              {agentList.encumbered_sharewallet
                                .toString()
                                .slice(1, 4)}
                            </span>
                            <span>
                              {agentList.encumbered_sharewallet
                                .toString()
                                .slice(4)}
                            </span>
                          </span>
                        ) : (
                          <span>{agentList.encumbered_sharewallet}</span>
                        )}
                      </td>
                    </tr>
                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        style={{
                          width: "50%",
                          paddingLeft: "5%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Contributions in unencumbered accounts:
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        {/* {agentList.unencumbered_sharewallet.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD"
                          }
                        )} */}
                        {pii ? (
                          <span>
                            $
                            <span
                              style={{
                                backgroundColor: "black",
                                color: "black",
                                cursor: "not-allowed",
                                userSelect: "none"
                              }}
                            >
                              {agentList.unencumbered_sharewallet
                                .toString()
                                .slice(1, 4)}
                            </span>
                            <span>
                              {agentList.unencumbered_sharewallet
                                .toString()
                                .slice(4)}
                            </span>
                          </span>
                        ) : (
                          <span>{agentList.unencumbered_sharewallet}</span>
                        )}
                      </td>
                    </tr>
                    <tr style={{ background: "#F4F5F6" }}>
                      <td
                        style={{
                          width: "50%",
                          fontWeight: "bold",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Total Ops Admin Contributions:
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        {/* {agentList.total_opsreserve.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD"
                        })} */}
                        {pii ? (
                          <span>
                            $
                            <span
                              style={{
                                backgroundColor: "black",
                                color: "black",
                                cursor: "not-allowed",
                                userSelect: "none"
                              }}
                            >
                              {agentList.total_opsreserve
                                .toString()
                                .slice(1, 4)}
                            </span>
                            <span>
                              {agentList.total_opsreserve.toString().slice(4)}
                            </span>
                          </span>
                        ) : (
                          <span>{agentList.total_opsreserve}</span>
                        )}
                      </td>
                    </tr>
                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        colSpan="2"
                        style={{
                          width: "50%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Breakups by 1st and 2nd month contributions
                      </td>
                    </tr>
                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        style={{
                          width: "50%",
                          paddingLeft: "5%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Admin bucket amount 1st month:
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        {/* {agentList.admin_month_one.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD"
                        })} */}
                        {pii ? (
                          <span>
                            $
                            <span
                              style={{
                                backgroundColor: "black",
                                color: "black",
                                cursor: "not-allowed",
                                userSelect: "none"
                              }}
                            >
                              {agentList.admin_month_one.toString().slice(1, 4)}
                            </span>
                            <span>
                              {agentList.admin_month_one.toString().slice(4)}
                            </span>
                          </span>
                        ) : (
                          <span>{agentList.admin_month_one}</span>
                        )}
                      </td>
                    </tr>
                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        style={{
                          width: "50%",
                          paddingLeft: "5%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Admin bucket amount 2nd month:
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        {/* {agentList.admin_month_two.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD"
                        })} */}
                        {pii ? (
                          <span>
                            $
                            <span
                              style={{
                                backgroundColor: "black",
                                color: "black",
                                cursor: "not-allowed",
                                userSelect: "none"
                              }}
                            >
                              {agentList.admin_month_two.toString().slice(1, 4)}
                            </span>
                            <span>
                              {agentList.admin_month_two.toString().slice(4)}
                            </span>
                          </span>
                        ) : (
                          <span>{agentList.admin_month_two}</span>
                        )}
                      </td>
                    </tr>
                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        colSpan="2"
                        style={{
                          width: "50%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Breakups by encumbered and unencumbered accounts
                      </td>
                    </tr>

                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        style={{
                          width: "50%",
                          paddingLeft: "5%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Contributions of encumbered accounts:{" "}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        {/* {agentList.encumbered_opsreserve.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD"
                          }
                        )} */}
                        {pii ? (
                          <span>
                            $
                            <span
                              style={{
                                backgroundColor: "black",
                                color: "black",
                                cursor: "not-allowed",
                                userSelect: "none"
                              }}
                            >
                              {agentList.encumbered_opsreserve
                                .toString()
                                .slice(1, 4)}
                            </span>
                            <span>
                              {agentList.encumbered_opsreserve
                                .toString()
                                .slice(4)}
                            </span>
                          </span>
                        ) : (
                          <span>{agentList.encumbered_opsreserve}</span>
                        )}
                      </td>
                    </tr>
                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        style={{
                          width: "50%",
                          paddingLeft: "5%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Contributions of unencumbered accounts:
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        {/* {agentList.unencumbered_opsreserve.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD"
                          }
                        )} */}
                        {pii ? (
                          <span>
                            $
                            <span
                              style={{
                                backgroundColor: "black",
                                color: "black",
                                cursor: "not-allowed",
                                userSelect: "none"
                              }}
                            >
                              {agentList.unencumbered_opsreserve
                                .toString()
                                .slice(1, 4)}
                            </span>
                            <span>
                              {agentList.unencumbered_opsreserve
                                .toString()
                                .slice(4)}
                            </span>
                          </span>
                        ) : (
                          <span>{agentList.unencumbered_opsreserve}</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style={{ marginTop: "20px" }}>
                <table className="table" style={{ border: "1px solid #ddd" }}>
                  <tbody>
                    <tr style={{ background: "#D4D5D6" }}>
                      <td
                        colSpan="2"
                        style={{
                          width: "100%",
                          color: "black",
                          fontSize: "16px"
                        }}
                      >
                        Refunds & Chargebacks
                      </td>
                    </tr>
                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        style={{
                          width: "50%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Total refund:
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        {/* {agentList.total_refund.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD"
                        })} */}
                        {pii ? (
                          <span>
                            $
                            <span
                              style={{
                                backgroundColor: "black",
                                color: "black",
                                cursor: "not-allowed",
                                userSelect: "none"
                              }}
                            >
                              {agentList.total_refund.toString().slice(1, 4)}
                            </span>
                            <span>
                              {agentList.total_refund.toString().slice(4)}
                            </span>
                          </span>
                        ) : (
                          <span>{agentList.total_refund}</span>
                        )}
                      </td>
                    </tr>
                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        style={{
                          width: "50%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Total chargeback:
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        {/* {agentList.total_chargeback.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD"
                        })} */}
                        {pii ? (
                          <span>
                            $
                            <span
                              style={{
                                backgroundColor: "black",
                                color: "black",
                                cursor: "not-allowed",
                                userSelect: "none"
                              }}
                            >
                              {agentList.total_chargeback
                                .toString()
                                .slice(1, 4)}
                            </span>
                            <span>
                              {agentList.total_chargeback.toString().slice(4)}
                            </span>
                          </span>
                        ) : (
                          <span>{agentList.total_chargeback}</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style={{ marginTop: "20px" }}>
                <table className="table" style={{ border: "1px solid #ddd" }}>
                  <tbody>
                    <tr style={{ background: "#D4D5D6" }}>
                      <td
                        colSpan="2"
                        style={{
                          width: "100%",
                          color: "black",
                          fontSize: "16px"
                        }}
                      >
                        Net-Net
                      </td>
                    </tr>
                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        style={{
                          width: "50%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Total NET balance for encumbered accounts:
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        {/* {agentList.total_contrib_encumbered.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD"
                          }
                        )} */}
                        {pii ? (
                          <span>
                            $
                            <span
                              style={{
                                backgroundColor: "black",
                                color: "black",
                                cursor: "not-allowed",
                                userSelect: "none"
                              }}
                            >
                              {agentList.total_contrib_encumbered
                                .toString()
                                .slice(1, 4)}
                            </span>
                            <span>
                              {agentList.total_contrib_encumbered
                                .toString()
                                .slice(4)}
                            </span>
                          </span>
                        ) : (
                          <span>{agentList.total_contrib_encumbered}</span>
                        )}
                      </td>
                    </tr>
                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        style={{
                          width: "50%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Total NET balance for unencumbered accounts:
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        {/* {agentList.total_contrib_unencumbered.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD"
                          }
                        )} */}
                        {pii ? (
                          <span>
                            $
                            <span
                              style={{
                                backgroundColor: "black",
                                color: "black",
                                cursor: "not-allowed",
                                userSelect: "none"
                              }}
                            >
                              {agentList.total_contrib_unencumbered
                                .toString()
                                .slice(1, 4)}
                            </span>
                            <span>
                              {agentList.total_contrib_unencumbered
                                .toString()
                                .slice(4)}
                            </span>
                          </span>
                        ) : (
                          <span>{agentList.total_contrib_unencumbered}</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style={{ marginTop: "20px" }}>
                <table className="table" style={{ border: "1px solid #ddd" }}>
                  <tbody>
                    <tr style={{ background: "#D4D5D6" }}>
                      <td
                        colSpan="2"
                        style={{
                          width: "100%",
                          color: "black",
                          fontSize: "16px"
                        }}
                      >
                        Needs
                      </td>
                    </tr>

                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        style={{
                          width: "50%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Last need imported date:
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        <Moment format="YYYY/MM/DD">
                          {agentList.Last_claim_imported_date}
                        </Moment>
                      </td>
                    </tr>
                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        style={{
                          width: "50%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Last need date:
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        <Moment format="YYYY/MM/DD">
                          {agentList.Last_claim_date}
                        </Moment>
                      </td>
                    </tr>
                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        style={{
                          width: "50%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Need Line Items:
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        {agentList.total_number_of_needs}
                      </td>
                    </tr>
                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        style={{
                          width: "50%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Number of paid needs:
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        {agentList.no_of_valid_needs}
                      </td>
                    </tr>
                    <tr style={{ background: "#FFFFFF" }}>
                      <td
                        style={{
                          width: "50%",
                          fontSize: "14px",
                          textAlign: "left"
                        }}
                      >
                        Need amount paid:
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px"
                        }}
                      >
                        {/* {agentList.paid_need_amount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD"
                        })} */}
                        {pii ? (
                          <span>
                            $
                            <span
                              style={{
                                backgroundColor: "black",
                                color: "black",
                                cursor: "not-allowed",
                                userSelect: "none"
                              }}
                            >
                              {agentList.paid_need_amount
                                .toString()
                                .slice(1, 4)}
                            </span>
                            <span>
                              {agentList.paid_need_amount.toString().slice(4)}
                            </span>
                          </span>
                        ) : (
                          <span>{agentList.paid_need_amount}</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      )}
    </ContentWrapper>
  );
};

export default ShareplusSummary;

