import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";
import { useSelector, useDispatch } from "react-redux";
import { setChannels, setHouseholdPastDue } from '../../../../redux/actions/productActions';
import MaterialTable ,{ MTableToolbar }from 'material-table';
import "bootstrap-daterangepicker/daterangepicker.css";
import { ExportToCsv } from "export-to-csv";



const PastDue = () => {
  const now = new Date();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now
    .getDate()
    .toString()
    .padStart(2, "0");
  const tempToDate = `${now.getFullYear()}-${month}-${day}`;

  const [load, setLoad] = useState(false);
  const [agentList, setAgentList] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const [agentListCall, setAgentListCall] = useState(true);
  const [selectedChannel, setSelectedChannel] = useState("All");
  const [fromDate, setFromDate] = useState("2011-06-09");
  const [toDate, setToDate] = useState(tempToDate);
  const [empId, setEmpId] = useState(true);
  const [empList, setEmpList] = useState([]);
  const [empClick, setEmpClick] = useState(false);
  const [empLoad, setEmpLoad] = useState(false);
 const pii= JSON.parse(localStorage.getItem('PII'))

  let username = localStorage.getItem(
    "CognitoIdentityServiceProvider.3dqtf4c0g43c047gkrsadgutp6.LastAuthUser"
  );
  let id_token = localStorage.getItem(
    "CognitoIdentityServiceProvider.3dqtf4c0g43c047gkrsadgutp6." +
      username +
      ".idToken"
  );

  const dataList = useSelector((state) => state);
  const dispatch = useDispatch();
  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: "PastDue"
  };
  const csvExporter = new ExportToCsv(options);
  async function fetchList() {
    setLoad(true);
    const request = await axios.get(
      config.regulatorPastDue +
      "from_date=" +
      fromDate +
      "&to_date=" +
      toDate +
      "&channel=" +
      selectedChannel,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
    if (dataList.channels.householdPastDue.length === 0) {
      dispatch(setHouseholdPastDue(request.data[0].payload));
    }
    setAgentList(request.data[0].payload);
    setLoad(false);
  }
  async function downloadList() {
  
    const request = await axios.get(
      config.regulatorPastDue +
      "from_date=" +
      fromDate +
      "&to_date=" +
      toDate +
      "&channel=" +
      selectedChannel,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
  
    csvExporter.generateCsv(request.data[0].payload);
  }
          async function fetchChannelList() {
          const request = await axios.get(config.channelList, {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": apikey.xapikey,
              token: id_token
            }
          });
          dispatch(setChannels(request.data));
          setChannelList(request.data);
        }
              async function fetchEmp() {
        const request = await axios.get(config.empDetails + empId, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": apikey.xapikey,
            token: id_token
          }
        });
        setEmpLoad(false);
        if (request.data !== null) {
          setEmpList(request.data);
        }
      }
  useEffect(() => {
    if (agentListCall === true) {
      setAgentListCall(false);
      if(dataList.channels.householdPastDue.length > 0 && (agentList[0] === 'null' || agentList[0] === undefined)) {
        setAgentList(dataList.channels.householdPastDue);
      } else {
        setLoad(true);
        setAgentListCall(false);

        fetchList();
      }

    }
    setChannelList(dataList.channels.channels);

    if (channelList.length === 0) {
      if (dataList.channels.channels.length === 0) {

        fetchChannelList();
      }
    }
    if (empClick === true) {
      setEmpClick(false);
      setEmpLoad(true);

      fetchEmp();
    }
  }, [
    agentListCall,
    agentList,
    channelList,
    empClick
  ]);


  const empIdClick = id => {
    console.log(id)
    setEmpList([]);
    setEmpClick(true);
    setEmpId(id);
    document.getElementById("modalEmpBtn").click();
  };
  const GetActionEmpIdFormat = (rowData) => {
    return (
      <div>
        <label
          className="empName"
          size="sm"
          onClick={() => empIdClick(rowData.empi)}
        >
          {rowData.empi}
        </label>
      </div>
    );
  };





  const channelSelect = e => {
    e.preventDefault();
    setAgentListCall(true);
    setSelectedChannel(e.target.value);
    fetchList();
  };
  const GetformatedAge = (cell) => {

    return (
      <div className={(cell.due_days < 30) ? "none" : (cell.due_days >= 30 && cell.due_days < 60) ?"greenAgecls" : (cell.due_days >= 60 && cell.due_days < 100) ? "yellowAgecls" : "redAgecls"}>
        {cell.due_days}
      </div>
    );
  };
  const columns = [
    {
      field: "empi",   
      title: "EMPI",
      render: rowData=>GetActionEmpIdFormat(rowData)
  
    },
    {
      field: "per_month_share",   
      title: "Monthly Share",
  
    },{
      field: "due_days",   
      title: "Aging",
      render: rowData=>GetformatedAge(rowData)
  
    },
    {
      field: "dependent_count",   
      title: "Dependent Count",
     
  
    },
    {
      field: "benefit_begin",
      title: "Benefit Begin",
      cellStyle: {
        width: '200px',
      }
    },
    
    {
      field: "plan_id",
      title: "Plain ID"
    },
    
    // {
    //     field: "first_name",
    //     title: "First Name",
    //     render: rowData => GetRedactFormat(rowData.first_name)
    // },

    // {
    //     field: "last_name",
    //     title: "Last Name",
    //     render: rowData => GetRedactFormat(rowData.last_name)
        
    // },

    {
        field: "paid_through_date",
        title: "Paid Throught date",
        cellStyle: {
          width: '220px',
        }
    },
  ];



  return (
    <ContentWrapper>
      <span className="pageheading">Past Due Report</span>
      <button
        data-toggle="modal"
        id="modalEmpBtn"
        data-target="#modalEmp"
        style={{ display: "none" }}
      >
        Emp
      </button>

      <div
        className="modal fade"
        id="modalEmp"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered dialogEmpWidth">
          <div className="modal-content">
            <div className="modalHeading">Member Info</div>
            <div className="modalContentDiv">
              {empLoad === true && <div className="loader" />}
              {empLoad === true && (
                <div style={{ textAlign: "center" }}>Loading Member Info</div>
              )}
              {empLoad === false && (
                <div className="empDiv">
                  <div className="empDataDiv">
                    <span className="empQus" >Name :</span>{" "}
                    {pii == true ? (<span style={{ backgroundColor: "black", color: "black", cursor: "not-allowed" }}>
                      {empList.first_name}&nbsp; {empList.last_name}
                    </span>) :
                      (<span >
                        {empList.first_name}&nbsp; {empList.last_name}
                      </span>)}
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">Emp Id :</span>{" "}
                    <span>{empList.empi}</span>
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">Gender :</span>{" "}
                    {pii == true ? (<span style={{ backgroundColor: "black", color: "black", cursor: "not-allowed" }}>{empList.gender}</span>) :
                      (<span >{empList.gender}</span>)
                    }
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">Plan Id :</span>{" "}
                    <span>{empList.plan_id}</span>
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">State Code :</span>{" "}
                    <span>{empList.state_code}</span>
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">Benefit Begin :</span>{" "}
                    <span>{empList.benefit_begin}</span>
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">Benefit End :</span>{" "}
                    <span>{empList.benefit_end}</span>
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">Paid Through Date:</span>{" "}
                    <span>{empList.paid_through_date}</span>
                  </div>
                  <hr />
                </div>
              )}
            </div>
            <div className="modalFooter">
              
              <button
                data-dismiss="modal"
                id="modalCloseBtn2"
                style={{ display: "block" }}
                className="modalFooterDoneBtn"
              >
                Done
              </button>
              <button
                data-dismiss="modal"
                id="modalCloseBtn1"
                style={{ display: "block" }}
                className="modalFooterCloseBtn"
              >
                Cancel
              </button>
            </div>

          </div>
        </div>
      </div>

      <div className="tableBody clearfix">
        {load === true && <div className="loader" />}
        {load === true && (
          <div style={{ textAlign: "center" }}>Loading List</div>
        )}

        {load === false && (
       
            <MaterialTable
            // other props
            components={{
              
              Toolbar: props => (
                  <div style={{ backgroundColor: '#f6f6e6' }}>
                      <MTableToolbar {...props} />
                      <select
          onChange={channelSelect}
          className="filterByDateButton channelFilterPosition searchInput"
          style={{ textAlign: "center" }}

        >
          <option value="All"> Select Channel </option>
          {channelList.map(item => (
            <option key={item.channel_name} value={item.channel_name}>
              {item.channel_abbr}
            </option>
          ))}
        </select>

        {/* <DateRangePicker
          onApply={handleEvent}
          parentEl={"#reportDRPContainerWallet"}
          ranges={{
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days")
            ],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
              moment()
                .subtract(1, "month")
                .startOf("month"),
              moment()
                .subtract(1, "month")
                .endOf("month")
            ],
            "Last Year": [moment().subtract(366, "days"), moment()],
            "Last 5 Years": [moment().subtract(1826, "days"), moment()]
          }}
          alwaysShowCalendars={true}
        >
          <button className="filterByDateButton dateFilterPosition">
            <img alt="" src={buttonSymbol.default} className="buttonSymbol" />
            Filter by Date
          </button>
        </DateRangePicker> */}
                  </div>
              )
          }}

          columns={columns}
          data={agentList}
          detailPanel={rowData => {

            return (
              <div className="detailPanel">
              <table className="detailPanelTable">
    
                <tr className="detailPaneltr">
                <th className="detailPanelth" scope="col">First Name</th>
                  <th className="detailPanelth" scope="col">Last Name</th>
                  <th className="detailPanelth" scope="col">Email</th>
                  <th className="detailPanelth" scope="col">Channel</th>

                </tr>
                <tr>
                <td className="detailPaneltd" > {pii ? (<div
                style={{ backgroundColor: "black", color: "black", cursor: "not-allowed" }}>
                  {rowData.email}</div>):  <div  >{rowData.first_name}</div>}</td>
                  <td className="detailPaneltd" > {pii ? (<div
                style={{ backgroundColor: "black", color: "black", cursor: "not-allowed" }}>
                  {rowData.email}</div>):  <div  >{rowData.last_name}</div>}</td>
                  <td className="detailPaneltd" > {pii ? (<div
                style={{ backgroundColor: "black", color: "black", cursor: "not-allowed" }}>
                  {rowData.email}</div>):  <div  >{rowData.email}</div>}</td>
                <td className="detailPaneltd" >{rowData.channel}</td>
                
               

                </tr>
              
            </table>
            </div>
            )
          }}
          options={{pageSize:10,searchFieldAlignment:"left",searchFieldStyle:{
            backgroundColor:"white",
            
          }}}
          title={false}
          actions={[
            {
              icon: 'save_alt',
              tooltip: 'Export Data',
              isFreeAction: true,
              iconProps:{style:{backgroundColor:"#eb5757",width:"35px",height:"35px",borderRadius:"20px",paddingTop:"6px",color:"white"}},
              onClick: () => downloadList(),
            },
            {
              icon: 'refresh',
              tooltip: 'Refresh Data',
              isFreeAction: true,
              iconProps:{style:{backgroundColor:"#41b5c2",width:"35px",height:"35px",borderRadius:"20px",paddingTop:"6px",color:"white"}},
              onClick: () => fetchList(),
            }
          ]}
        
          />
         
     
     
        )}
      </div>
    </ContentWrapper>
  );
};

export default PastDue;

