export const ActionTypes = {
    SET_CHANNELS: "SET_CHANNELS",
    SET_HOUSEHOLDREPORT: "SET_HOUSEHOLDREPORT",
    SET_FILE_PROCESS: "SET_FILE_PROCESS",
    SET_SHAREWALLET_BALANCE: "SET_SHAREWALLET_BALANCE",
    SET_ADMINRESERVE_MONTH: "SET_ADMINRESERVE_MONTH",
    SET_ADMINRESERVE_WEEK: "SET_ADMINRESERVE_WEEK",
    SET_YTD:"SET_YTD",
    SET_YTD_MONTH: "SET_YTD_MONTH",
    SET_YTD_WEEK: "SET_YTD_WEEK",
    SET_AGGREGATE_REPORT: "SET_AGGREGATE_REPORT",
    SET_TP_RULE: "SET_TP_RULE",
    SET_SUMMARY: "SET_SUMMARY",
    SET_NEEDS: "SET_NEEDS",
    SET_NEEDS_REQUEST: "SET_NEEDS_REQUEST",
    SET_NEEDS_AGGREGATE_MONTH: "SET_NEEDS_AGGREGATE_MONTH",
    SET_HOUSEHOLD_PASTDUE:"SET_HOUSEHOLD_PASTDUE",
    SET_HOUSEHOLD_MONTH: "SET_HOUSEHOLD_MONTH",
    SET_HOUSEHOLD_WEEK: "SET_HOUSEHOLD_WEEK",
    SET_MEMBER_MONTH: "SET_MEMBER_MONTH",
    SET_MEMBER_WEEK: "SET_MEMBER_WEEK",
    SET_HOUSEHOLD_QUERY: "SET_HOUSEHOLD_QUERY",
    SET_AGENCY_COMMISSION: "SET_AGENCY_COMMISSION",
    SET_AGENT_SALE: "SET_AGENT_SALE",
    SET_AGENT_COMMISSION: "SET_AGENT_COMMISSION"
};