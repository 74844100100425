import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";
import { ExportToCsv } from "export-to-csv";
import { useSelector, useDispatch } from "react-redux";
import { setNeedsAggregateMontly } from '../../../../redux/actions/productActions';
import MaterialTable ,{ MTableToolbar }from 'material-table';
const MonthlyNeedsAggregate = () => {
  const [load, setLoad] = useState(false);
  const [agentList, setAgentList] = useState([]);
  const [agentListCall, setAgentListCall] = useState(true);

    let username = localStorage.getItem(
      "CognitoIdentityServiceProvider.3dqtf4c0g43c047gkrsadgutp6.LastAuthUser"
    );
    let id_token = localStorage.getItem(
      "CognitoIdentityServiceProvider.3dqtf4c0g43c047gkrsadgutp6." +
        username +
        ".idToken"
    );
  const dataList = useSelector((state) => state);
  const dispatch = useDispatch();
  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: "Monthly Needs Aggregate"
  };
  const csvExporter = new ExportToCsv(options);
  async function fetchList() {
    setLoad(true);
    const request = await axios.get(
      config.regulatorMonthlyNeedsAggregate,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
    dispatch(setNeedsAggregateMontly(request.data));
    setAgentList(request.data);
    setLoad(false);
  }
  async function downloadList() {
  
    const request = await axios.get(
      config.regulatorMonthlyNeedsAggregate,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
  
    csvExporter.generateCsv(request.data);
  }
  useEffect(() => {
    if (agentListCall === true) {
      if (dataList.channels.needsAggregateMonthly.length > 0) {
        setAgentList(dataList.channels.needsAggregateMonthly);
      } else {
        setLoad(true);
        setAgentListCall(false);

        fetchList();
      }
    }
  }, [
      agentListCall,
      agentList
    ]);



  const columns = [
    {
      field: "year",
      title: "Year"
    },
    {
      field: "month",
      title: "Month"
    },
    {
      field: "sharewallet",
      title: "Claimswallet Balance"
    },
    {
      field: "cumulative_sharewallet",
      title: "Cumulative Claimswallet Balance"
    },
    {
      field: "needs_amount",
      title: "Needs Amount Spend"
    },
    {
      field: "cumulative_needs_amount",
      title: "Cumulative Need Amount Spend"
    },
    {
      field: "needs_count",
      title: "Need Count"
    },
    {
      field: "cumulative_needs_count",
      title: "Cumulative Need Count"
    }
  ];



  return (
    <ContentWrapper>
      <span className="pageheading"> Monthly Needs Aggregate </span>

      <div className="tableBody clearfix">
        {load === true && <div className="loader" />}
        {load === true && (
          <div style={{ textAlign: "center" }}>Loading List</div>
        )}

        {load === false && (
           <MaterialTable
           // other props
           components={{
             
             Toolbar: props => (
                 <div style={{ backgroundColor: '#f6f6e6' }}>
                      <MTableToolbar {...props} />
                 </div>
             )
         }}

         columns={columns}
         data={agentList}
         options={{pageSize:10,searchFieldAlignment:"left",searchFieldStyle:{
           backgroundColor:"white",
           
         }}}
         title={false}
         actions={[
           {
             icon: 'save_alt',
             tooltip: 'Export Data',
             isFreeAction: true,
             iconProps:{style:{backgroundColor:"#eb5757",width:"35px",height:"35px",borderRadius:"20px",paddingTop:"6px",color:"white"}},
             onClick: () => downloadList(),
           },
           {
             icon: 'refresh',
             tooltip: 'Refresh Data',
             isFreeAction: true,
             iconProps:{style:{backgroundColor:"#41b5c2",width:"35px",height:"35px",borderRadius:"20px",paddingTop:"6px",color:"white"}},
             onClick: () => fetchList(),
           }
         ]}
       
         />
        )}
      </div>
    </ContentWrapper>
  );
};

export default MonthlyNeedsAggregate;

